<template>
  <!-- 今日退款 -->
  <div class="todayRefund content-index">
    <div class="navTop">
      <el-page-header
        @back="$router.back()"
        content="退款列表"
      ></el-page-header>
    </div>
    <div class="formBox">
      <el-date-picker
        v-model="selectDate"
        type="daterange"
        size="medium"
        value-format="yyyy-MM-dd"
        @change="selectTimes"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
      ></el-date-picker>
      <el-select
        v-model="formData.refundWay"
        clearable
        placeholder="退款方式"
        size="medium"
        style="margin: 0 10px;"
      >
        <el-option
          v-for="item in payList"
          :key="item.itemValue"
          :label="item.itemText"
          :value="item.itemValue"
        />
      </el-select>
      <el-select
        v-model="formData.type"
        clearable
        placeholder="退款类型"
        size="medium"
        style="margin: 0 10px;"
      >
        <el-option
          v-for="item in refundType"
          :key="item.value"
          :label="item.text"
          :value="item.value"
        />
      </el-select>
      <el-input
        placeholder="请输入手机号/订单号"
        size="medium"
        v-model="formData.phoneOrOrder"
        style="width: 260px; margin-right: 10px;"
        clearable
      ></el-input>
      <el-button type="primary" size="medium" @click="queryInfo">
        确定
      </el-button>
    </div>
    <div class="tableTitle">
      <p>
        <span>退款金额:{{ refundTotal }}元</span>
      </p>
    </div>
    <div class="tableBox">
      <table class="tableCtn" cellspacing="0">
        <thead>
          <tr>
            <th>序号</th>
            <th>类型</th>
            <th>订单号</th>
            <th>联系人</th>
            <th>电话</th>
            <th>订单状态</th>
            <th>退款时间</th>
            <th>退款方式</th>
            <th>退款金额</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody v-loading="loading">
          <tr>
            <td colspan="10" v-if="tableData.length == 0">暂无数据</td>
          </tr>
          <tr v-for="(item, index) in tableData" :key="index">
            <td>{{
              (Number(formData.current) - 1) * 10 + index + 1
            }}</td>
            <td>
              <el-tag type="warning" v-if="item.refundType == 1">
                洗衣退款
              </el-tag>
              <el-tag type="danger" v-else-if="item.refundType == 3">
                充值退款
              </el-tag>
            </td>
            <td>
              {{ item.id }}
              <el-button @click.stop="handleCopy(item.id)" type="text">
                复制
              </el-button>
            </td>
            <td>{{ item.userName }}</td>
            <td>{{ item.phone }}</td>
            <!-- <el-button @click.stop="handleCopy(item.phone)" type="text">
              复制
            </el-button> -->
            <td>{{ item.orderStatus }}</td>
            <td>{{ item.payTime }}</td>
            <td>{{ item.payWay }}</td>
            <td>{{ item.payPrice }}</td>
            <td>
              <el-button size="mini" type="primary" @click="seeDetail(item)">
                详情
              </el-button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="footer">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          layout="prev,pager,next,sizes"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="formData.size"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <!-- 用户详情 -->
    <transition name="userDetails">
      <div class="memberDetailView" v-if="q">
        <div class="topView">
          <div class="backView">
            <el-button
              class="btn-back"
              icon="el-icon-arrow-left"
              @click="q = false"
            >
              返回
            </el-button>
          </div>
          <div class="contentView">
            <div class="tView">用户详情</div>
            <div class="menuView">
              <div
                v-for="(tab, index) in tabs"
                class="menuItem"
                :key="index"
                @click="toggleTab(tab.id)"
              >
                <label :class="{ select: currentTab === tab.id }">
                  {{ tab.title }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="contentView" style="height: 669px;">
          <!--用户信息-->
          <member-base-info
            :user-id="userId"
            v-if="currentTab === 0"
          ></member-base-info>
          <!--交易记录-->
          <!--          <div class="orderListView" v-if="currentTab === 1">-->
          <transaction-table
            v-if="currentTab === 1"
            :user-id="userId"
          ></transaction-table>
          <!--          </div>-->
          <recharge-record
            v-if="currentTab === 2"
            :user-id="userId"
          ></recharge-record>
          <!--优惠券-->
          <coupon-record
            v-if="currentTab === 4"
            :user-id="userId"
          ></coupon-record>
           <!-- 转余额记录 -->
          <transferRecord v-if="currentTab === 5" :user-id="userId" />
        </div>
      </div>
    </transition>

    <!-- 订单详情 -->
    <transition name="userDetails1">
      <!-- v-if="dialogTableVisible" -->
      <order-detail
        v-if="dialogTableVisible"
        :orderDetailOption="orderDetailOption"
        @diaLogIsShow="dialogClose"
        v-loading="assemblyLoading"
      ></order-detail>
    </transition>
  </div>
</template>

<script>
import {
  queryDictItemByDictName,
  getTodayRefund,
} from '@/api/todayData/index.js'
import RechargeRecord from "@/components/memberInfo/rechargeRecord";
import MemberBaseInfo from '@/components/memberInfo/memberBaseInfo'
import couponRecord from "@/components/memberInfo/couponRecord";
import transactionTable from "@/components/memberInfo/transactionTable";
import transferRecord from "@/components/memberInfo/transferRecord";
import { getClothesOrderDetails } from '@/api/clothes/order'
import OrderDetail from '@/components/OrderDetails'

export default {
  data() {
    return {
      loading: false,
      formData: {
        size: 10,
        current: 1,
        beginTime: '',
        endTime: '',
        refundWay: '',
        phoneOrOrder: '',
        type: '',
      },
      total: 0,
      selectDate: [
        this.$moment(new Date()).format('YYYY-MM-DD'),
        this.$moment(new Date()).format('YYYY-MM-DD'),
      ],
      payList: [],
      tableData: [],
      refundTotal: 0,
      refundType: [
        { value: 1, text: '订单退款' },
        { value: 3, text: '储值卡' },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: '昨天',
            onClick(picker) {
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', [start, start])
            },
          },
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime()
          if (maxDate) {
            this.selectDate = ''
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== '') {
            const one = 90 * 24 * 3600 * 1000
            const minTime = this.selectDate - one
            const maxTime = this.selectDate + one
            return (
              time.getTime() < minTime ||
              time.getTime() > maxTime ||
              time.getTime() > Date.now() - 8.64e6
            )
          }
        },
      },
      q: false,
      userId: '',
      cardNumber: 0,
      currentTab: 0,
      tabs: [
        { id: 0, title: '基本信息' },
        { id: 1, title: '交易记录' },
        { id: 2, title: '充值记录' },
        { id: 3, title: '积分记录' },
        { id: 4, title: '优惠券' },
        { id: 5, title: "转余额记录" },
      ],
      orderDetailOption: {
        clothesList: [],
      },
      dialogTableVisible: false,
      assemblyLoading: false,
    }
  },
  components: {
    RechargeRecord,
    MemberBaseInfo,
    couponRecord,
    transactionTable,
    OrderDetail,
    transferRecord
  },
  created() {
    this.selectTimes()
    this.getPayType()
    this.getPageInfo()
  },
  methods: {
    selectTimes() {
      this.formData.beginTime = this.selectDate ? this.selectDate[0] : ''
      this.formData.endTime = this.selectDate ? this.selectDate[1] : ''
    },
    toggleTab: function (index) {
      this.currentTab = index
    },
    queryInfo() {
      this.formData.current = 1
      this.getPageInfo()
    },
    async getPayType() {
      const { data: res } = await queryDictItemByDictName('支付方式')
      if (res.code != 0) return this.$message.error('获取支付方式失败')
      this.payList = res.data
    },
    async getPageInfo() {
      this.loading = true
      const { data: res } = await getTodayRefund(this.formData)
      if (res.code != 0) return this.$message.error(res.msg)
      this.tableData = res.data.list.map((item) => {
        item.phone =  item.phone.slice(0, 3) + "****" + item.phone.slice(7);
        return item
      })
      this.total = res.data.total
      this.refundTotal = res.data.rechargeTotalPrice
      this.loading = false
    },
    handleCurrentChange(val) {
      this.formData.current = val
      this.getPageInfo()
    },
    handleSizeChange(val) {
      this.formData.size = val
      this.getPageInfo()
    },
    seeDetail(data) {
      console.log(data)
      if (data.refundType == 1) {
        this.getClothesOrderDetails(data.id)
      } else if (data.refundType == 3) {
        this.q = true
        this.userId = data.userId
      }
    },

    //获取订单详情
    getClothesOrderDetails(orderNumber) {
      this.dialogTableVisible = true
      this.assemblyLoading = true
      this.orderNumber = orderNumber
      getClothesOrderDetails(orderNumber).then((response) => {
        this.assemblyLoading = false
        this.orderDetailOption = response.data.data
      })
    },
    dialogClose(isShow) {
      this.dialogTableVisible = isShow
    },
    // 处理复制
    handleCopy(value) {
      let _this = this
      _this.$copyText(value).then(
        function (e) {
          _this.$message.success('复制成功')
        },
        function (e) {
          _this.$message.error('复制失败')
        },
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.todayRefund {
  padding: 25px 20px;
  overflow: auto;

  .navTop {
    p {
      width: 60px;
      height: 30px;
      margin: 0;
      font-size: 16px;
      cursor: pointer;
      text-align: center;
      line-height: 30px;
      border-radius: 5px;

      &:hover {
        background-color: #ecf5ff;
        // color: #fff;
      }
    }
  }

  .formBox {
    margin-top: 20px;
  }

  .tableTitle {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 700;

    p {
      span {
        margin-right: 15px;
      }
    }
  }
}

.tableCtn {
  width: 100%;
  max-height: 800px;
  overflow: auto;

  thead {
    height: 40px;
    background-color: #f5f7fa;
    font-size: 14px;
    line-height: 40px;
  }

  td {
    height: 30px;
    text-align: center;
    line-height: 30px;
  }

  tbody {
    td {
      padding: 10px 0;
      font-size: 14px;
    }
  }
}

.footer {
  margin:20px 0;
  text-align: right;
}

.memberDetailView {
  position: fixed;
  left: 70px;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background: #fff;
  padding: 120px 20px 25px 25px;

  > .topView {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 115px;
    padding: 20px 25px 0 25px;
    z-index: 100;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);

    > .backView {
      height: 30px;

      > .btn-back {
        font-size: 14px;
        color: #666;
        height: 30px;
        padding: 0;
        border: none;
        text-align: left;
      }
    }

    > .contentView {
      display: flex;
      position: relative;
      height: 55px;
      padding: 10px 0 15px 0;
      overflow: hidden;

      > .tView {
        margin-top: 0;
        margin-right: 20px;
        width: 150px;
        font-family: PingFang-SC-Bold;
        font-size: 24px;
        color: #333;
        text-align: left;
        line-height: 40px;
      }

      > .menuView {
        flex: 1;
        height: 60px;
        line-height: 40px;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        /* padding: 0 100px 0 160px; */
        /*> /deep/ .el-tabs__nav-wrap::after {*/
        /*    position: static !important;*/
        /*}*/

        .menuItem {
          display: inline-block;
          min-width: 60px;
          text-align: center;
          padding: 0 25px;
          cursor: pointer;
          font-family: PingFangSC-Regular;
          font-size: 16px;
          color: #333;

          > .select {
            position: relative;
            color: #3370ff;
          }

          > .select:after {
            content: '';
            left: 0;
            right: 0;
            bottom: -8px;
            position: absolute;
            width: 24px;
            height: 2px;
            background: #3370ff;
            margin: auto;
            border-radius: 1px;
          }
        }
      }

      > .btnView {
        width: 132px;
        display: flex;

        > button {
          flex: 1;
          margin-right: 12px;
          height: 40px;
          background: #47bf7c;
          color: #fff;
          font-size: 14px;
          font-family: PingFangSC-Medium;
          border-radius: 4px;
        }

        > button:last-child {
          margin-right: 0;
          background: #3370ff;
        }
      }
    }
  }
}

//动画过程
.userDetails-enter {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.userDetails-leave {
  animation: fullscreen-dialog-fade-out 0.9s ease forwards;
}

.userDetails-enter-active {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.userDetails-leave-active {
  animation: fullscreen-dialog-fade-out 0.9s ease forwards;
}

@keyframes fullscreen-dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}

//动画过程
.userDetails1-enter {
  animation: fullscreen-dialog-fade-in 0.6s ease;
}

.userDetails1-leave {
  animation: fullscreen-dialog-fade-out 0.6s ease forwards;
}

.userDetails1-enter-active {
  animation: fullscreen-dialog-fade-in 0.6s ease;
}

.userDetails1-leave-active {
  animation: fullscreen-dialog-fade-out 0.6s ease forwards;
}

@keyframes fullscreen-dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}
</style>
