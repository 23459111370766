import request from "@/utils/request.js"
//实收数据
export const getTodayNetReceipts = (params)=>{
	return request({
		url:'order/order/actualIncome',
		method:'GET',
		params
	})
}

//字典数据
export const queryDictItemByDictName = (dictName) => {
  return request({
    url: '/sys/dict/queryDictItemByDictName/' + dictName,
    method: 'get',
  })
}
//充值数据
export const getTodayRechange = (params)=>{
	return request({
		url:'member/membercard/record/queryRechargerCard',
		method:'GET',
		params
	})
}

//退款数据
export const getTodayRefund = (params)=>{
	return request({
		url:'order/refundrecord/queryRefundPage',
		method:'GET',
		params
	})
}